// Home.js
import React from 'react';
// import About from './About';
import { Button, Container, Row, Col } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import { NavLink } from 'react-router-dom';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import '../styles/home.css';
import '../styles/home_mobile.css';
import { useCountdown } from '../hooks/useCountdown';
// import Slider from "react-slick";
// import img from '../../img';



// const Home = () => <h1 className='h_ita'>Home Page</h1>;

function Home() {

    // const now = new Date()
    const [days, hours, minutes, seconds] = useCountdown('August 31, 24 11:30:00 GMT+01');

    const images = require.context('../img/carousel', true);
    const imageList = images.keys().map(image => images(image));

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
            slidesToSlide: 4 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 768 },
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 767, min: 464 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    // imageList.map((image, index) => (console.log(image)));

    return (
        <>
            <section id='home'>
                <Container>
                    <Row id="timer">
                        <Col className='tm-cont bb'><p>{days}</p> Jours<br /><br /></Col>
                        <Col className='tm-cont bb'><p>{hours}</p>  Heures<br /><br /></Col>
                        <Col className='tm-cont bb'><p>{minutes}</p>  Minutes<br /><br /></Col>
                        <Col className='tm-cont'><p>{seconds}</p>   Secondes<br /><br /></Col>
                    </Row>
                    <Row className='spacer1'></Row>
                    <Row id='lela'>
                        <p>L É A &ensp;& <br />L A L A I N A</p>
                    </Row>
                    <Row className='spacer2'></Row>
                    <Row id='info'>
                        <Row>
                            <h3>LALE / SAINT-FÉLIX DE PALLIÈRES<br /><br /></h3>
                        </Row>
                        <Row>
                            <h5><br />SAMEDI 31 AOÛT 2024 11H30</h5>
                        </Row>
                    </Row>
                </Container>
            </section>
            <section id='about'>
                <Container id='about_filter'>
                    <Row className='spacer2'>
                    </Row>
                    <Row className='spacer1'>
                        <Col className='bb'></Col>
                        <Col></Col>
                    </Row>
                    <Row className='spacer2'></Row>
                    <Row id='prog'>
                        <p>PROGRAMME</p>
                    </Row>
                    <Row className='spacer2'></Row>
                    <Row id='about_info'>
                        <Col className='bb prog_left'>
                            <p><br />11H30 <br /> VODIONDRY</p>
                            <p><br /><br /><br />16H00 <br /> MAIRIE</p>
                        </Col>
                        <Col className='prog_right'>
                            <p><br /><br /><br /><br />12H30 <br /> REPAS</p>
                            <p><br /><br /><br />19H00 <br /> SOIRÉE<br /><br /></p>
                        </Col>
                    </Row>
                    <Row className='spacer1'></Row>
                </Container>
            </section>
            <section id='question'>
                <Container>
                    <Row className='spacer1'></Row>
                    <Row id='qu_tittle'>
                        <p>QUESTIONS</p>
                    </Row>
                    <Row className='spacer2'></Row>
                    <Row id='qr'>
                        <Col className='qr_col'>
                            <p className='qr_tittle'>COMMENT S'Y RENDRE ?</p>
                            <p className='qr_content'>LE LIEU EST ACCESSIBLE EN VOITURE.
                                L’ADRESSE “LALE - SAINT FÉLIX DE PALLIÈRES” EST DISPONIBLE SUR TOUS LES GPS.</p>
                        </Col>
                        <Col className='qr_col'>
                            <p className='qr_tittle'>QUEL EST LE DRESS CODE ?</p>

                            <p className='qr_content'>PAS DE DRESS CODE. CEPENDANT L’ÉTÉ EST LÀ, LES COULEURS DE SAISON OU LES THÈMES FLEURIS SERONT LES BIENVENUS.</p>
                        </Col>
                        <Col className='qr_col'>
                            <p className='qr_tittle'>ET POUR LE CADEAU ?</p>
                            <p className='qr_content'>RAMENEZ VOTRE BONNE HUMEUR AINSI QU’UNE BONNE BOUTEILLE DE CHAMPAGNE POUR QUE NOUS FÊTIONS ÇA ENSEMBLE.</p>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section id='carousel'>
                <Carousel
                    responsive={responsive}
                    autoPlay={true}
                    swipeable={true}
                    draggable={true}
                    showDots={true}
                    infinite={true}
                >
                    {imageList.map((image, index) => (

                        <Carousel.Item>
                            <img height={window.innerHeight} key={index} src={image} alt={`image-${index}`} />
                        </Carousel.Item>
                    ))}
                </Carousel>

            </section>
            {/* <section id='contact'>
                <Container>
                    <Row className='spacer2'></Row>
                    <Row id='contact_title'>
                        <p>CONTACTEZ-NOUS</p>
                    </Row>
                    <Row id='contact_info'>
                        <Col className='contact_col'>
                            <p className='contact_tittle'>ADRESSE</p>
                            <p className='contact_content'>LALE, 30140 SAINT-FÉLIX DE PALLIÈRES, FRANCE</p>
                        </Col>
                        <Col className='contact_col'>
                            <p className='contact_tittle'>TÉLÉPHONE</p>
                            <p className='contact_content'>+33 6 12 34 56 78</p>
                        </Col>
                        <Col className='contact_col'>
                            <p className='contact_tittle'>EMAIL</p>
                            <p className='contact_content'>contact@example.com</p>
                        </Col>
                    </Row>
                    <Row className='spacer2'></Row>
                </Container>
            </section> */}
        </>
    );
}
export default Home; 