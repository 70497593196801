import React, { useState } from "react";
import { FloatingLabel, Form, Button, Container, Row, Col } from "react-bootstrap";
import InputGroup from 'react-bootstrap/InputGroup';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../styles/RSVP.css";

function Formulaire() {
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [response, setResponse] = useState("");
    const [isCommingForBrunch, setIsCommingForBrunch] = useState(false);
    const [isCommingBefore, setIsisCommingBefore] = useState(false);
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const handleEmailChange = (e) => setEmail(e.target.value);
    const handlePhoneNumberChange = (e) => setPhoneNumber(e.target.value);
    const handleFirstNameChange = (e) => setFirstName(e.target.value);
    const handleLastNameChange = (e) => setLastName(e.target.value);
    const handleResponse = (e) => setResponse(e.target.value);
    const handleIsCommingForBrunch = (e) => setIsCommingForBrunch(e.target.checked);
    const handleIsCommingBefore = (e) => setIsisCommingBefore(e.target.checked);

    const header = {
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "*"
    }

    const info = {
        email,
        phoneNumber,
        firstName,
        lastName,
        response,
        isCommingForBrunch,
        isCommingBefore
    };

    const validateForm = () => {
        if (!email || !phoneNumber || !firstName || !lastName) {
            setError("Tous les champs doivent être remplis.");
            return false;
        }
        // Additional validation for phone number (French format)
        const phoneRegex = /^(\+33)[1-9]\d{8}$/;
        if (!phoneRegex.test(phoneNumber)) {
            setError("Numéro de téléphone invalide. Assurez-vous qu'il est au format français. exemple: \"+33612345678\"");
            return false;
        }
        setError("");
        return true;
    };

    const checkIfRegistered = async () => {
        try {
            const response = await axios.get(`https://api.lea-lalaina.com/check-registration`, {
                params: { email, phoneNumber }
            }, header);
            return response.data.isRegistered;
        } catch (error) {
            console.error("Erreur lors de la vérification de l'enregistrement:", error);
            return false;
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateForm()) return;

        const isRegistered = await checkIfRegistered();
        if (isRegistered) {
            setError("Cet invité est déjà enregistré.");
            return;
        }

        try {
            await axios.post("https://api.lea-lalaina.com/submit", info, header);
            navigate("/");
        } catch (error) {
            console.error("Erreur lors de l'envoi des données:", error);
            setError("Une erreur est survenue lors de l'envoi des données.");
        }
    };

    return (
        <section id="RSVPcontainer">
            <Container>
                <Row><br /><br /></Row>
                <Form className="RSVP-form" onSubmit={handleSubmit}>
                    <h1 className="text-center">RSVP</h1>
                    {error && <p className="text-danger">{error}</p>}
                    <Row>
                        <Col>
                            <Form.Group className="form-group" controlId="email">
                                <FloatingLabel controlId="Email" label="Email" className="mb-3">
                                    <Form.Control
                                        type="email"
                                        placeholder="Email"
                                        value={email}
                                        onChange={handleEmailChange}
                                    />
                                </FloatingLabel>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="form-group" controlId="phoneNumber">
                                <FloatingLabel controlId="tel" label="Télephone" className="mb-3">
                                    <Form.Control
                                        type="tel"
                                        placeholder="+33612345678"
                                        value={phoneNumber}
                                        onChange={handlePhoneNumberChange}
                                    />
                                </FloatingLabel>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="form-group" controlId="lastName">
                                <FloatingLabel controlId="text" label="Nom" className="mb-3">
                                    <Form.Control
                                        type="text"
                                        placeholder={`Nom`}
                                        value={lastName}
                                        onChange={handleLastNameChange}
                                    />
                                </FloatingLabel>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="form-group" controlId="firstName">
                                <FloatingLabel controlId="FirstName" label="Prénom" className="mb-3">
                                    <Form.Control
                                        type="text"
                                        placeholder={`Prénom`}
                                        value={firstName}
                                        onChange={handleFirstNameChange}
                                    />
                                </FloatingLabel>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Form.Group>
                            <FloatingLabel controlId="response" label="Réponse" className="mb-3">
                                <Form.Select aria-label="Réponse" onChange={handleResponse}>
                                    <option value="mairie">Je serai présent.e et je repartirai après la mairie</option>
                                    <option value="party">Je serai présent.e et je resterai pour la soirée</option>
                                    <option value="not_comming">Je ne pourrai pas être présent.e</option>
                                </Form.Select>
                            </FloatingLabel>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group>
                            <Form.Check type={"switch"}>
                                <Form.Check.Input
                                    type={"checkbox"}
                                    defaultChecked={false}
                                    onChange={handleIsCommingBefore}
                                />
                                <Form.Check.Label>J'arriverai sur place le vendredi 30 août</Form.Check.Label>
                            </Form.Check>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group>
                            <Form.Check type={"switch"}>
                                <Form.Check.Input
                                    type={"checkbox"}
                                    defaultChecked={false}
                                    onChange={handleIsCommingForBrunch}
                                />
                                <Form.Check.Label>Je passerai le lendemain matin pour un café/brunch</Form.Check.Label>
                            </Form.Check>
                        </Form.Group>
                    </Row>
                    <Button type="submit">Valider</Button>
                </Form>
            </Container>
        </section>
    );
}

export default Formulaire;
